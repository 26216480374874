/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { ISource } from '../../types';
import { useModal } from '../../hooks';
import { back, forward } from '../../assets';
import styles from './styles.module.css';

export interface ImageProps {
	source: ISource;
	alt: string;
	className?: string;
	popup?: boolean;
	responsive?: boolean;
}

const Image = (props: ImageProps): JSX.Element => {
	const { source, alt, className, popup, responsive } = props;
	const [isLoading, setLoading] = useState<boolean>(true);
	const [index, setIndex] = useState<number>(-1);
	const [gallery, setGallery] = useState<HTMLImageElement[]>([]);
	const imageRef = useRef<HTMLImageElement>(null);
	const popupImageRef = useRef<HTMLImageElement>(null);
	const { setModalVisible, setModalContent } = useModal();
	const handleLoad = () => setLoading(false);
	const handleNext = () => setIndex(() => (index < gallery.length ? index + 1 : index));
	const handlePrev = () => setIndex(() => (index > 0 ? index - 1 : index));

	const srcSet = useMemo(
		(): string =>
			Object.entries(source['image/jpeg'])
				.map(([width, path]) =>
					process.env.NODE_ENV === 'development'
						? `https://pc.stacha.dev${path} ${width}w, `
						: `${path} ${width}w, `
				)
				.join(', '),
		[source]
	);

	const maxSrc = useMemo((): string => {
		const sizes = Object.entries(source['image/jpeg']) ?? [];
		return process.env.NODE_ENV === 'development'
			? `https://pc.stacha.dev${sizes[sizes.length - 1][1]}`
			: sizes[sizes.length - 1][1];
	}, [source]);

	useEffect(() => {
		setGallery(Array.from(document.querySelectorAll(`.${styles.popup}`)));
	}, []);

	useEffect(() => {
		gallery[index] &&
			setModalContent(
				<>
					<div className={styles.controls}>
						<button type="button" onClick={handlePrev} className={styles.button}>
							<img alt="back" src={back} />
						</button>
						<button type="button" onClick={handleNext} className={styles.button}>
							<img alt="forward" src={forward} />
						</button>
					</div>
					<img
						srcSet={gallery[index].srcset}
						sizes="100vw"
						alt={gallery[index].alt}
						style={{ width: '100%', maxWidth: '85%', maxHeight: '90%', objectFit: 'contain' }}
						ref={popupImageRef}
					/>
				</>
			);
	}, [index]);

	const handleOpen = ({ target }: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
		if (popup && window.innerWidth > 425) {
			const current = gallery.find(
				(image) => (target as HTMLImageElement).srcset === (image as HTMLImageElement).srcset
			);
			setIndex(() => (current ? gallery.indexOf(current) : 0));
			setModalVisible(true);
		}
	};

	return responsive ? (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<img
			alt={alt}
			srcSet={srcSet}
			onLoad={handleLoad}
			className={`${className && className} ${isLoading ? styles.loading : ''} ${popup ? styles.popup : ''}`}
			sizes={`${imageRef.current?.width}px`}
			onClick={handleOpen}
			loading="lazy"
			ref={imageRef}
		/>
	) : (
		<img
			srcSet={maxSrc}
			alt={alt}
			onLoad={handleLoad}
			className={`${className && className} ${isLoading ? styles.loading : ''} ${popup ? styles.popup : ''}`}
			loading="lazy"
			ref={imageRef}
		/>
	);
};

Image.defaultProps = {
	clasName: '',
	popup: false,
	responsive: false,
};

export default Image;
