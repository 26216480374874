import React from 'react';
import styles from './styles.module.css';

const Footer = (): JSX.Element => {
	const year = new Date().getFullYear();

	return (
		<footer className={styles.container}>
			<div className={styles.item}>© Martina Schneider Králová {year}, všechna práva vyhrazena.</div>
			<div className={styles.item}>tel. +420 603 549 820</div>
			<div className={styles.item}>
				Web <a href="https://stacha.dev/">Stacha.dev</a>
			</div>
			<div className={styles.item}>Grafický návrh Karolína Hůlová</div>
		</footer>
	);
};

export default Footer;
