import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from '../../contexts';
import { IGallery, SectionProps, Type } from '../../types';
import Title from '../Title';
import Item from './Item';
import Next from '../Next';
import styles from './styles.module.css';

const Realizations = (props: SectionProps): JSX.Element => {
	const { anchor } = props;
	const [galleries, setGalleries] = useState<IGallery[]>([]);
	const { tardis } = useContext(StoreContext);

	useEffect(() => {
		tardis.find('tag', '2').then((result) => setGalleries(() => result.filter(({ state }) => state)));
	}, [tardis]);

	return (
		<section id={anchor}>
			<Title text="realizace" type={Type.secondary} />
			<span className={styles.pick}>výběr</span>
			<div className={styles.container}>
				{galleries
					.filter((gallery, index) => gallery.id > 1 && index <= 4)
					.map((gallery) => (
						<Item
							key={gallery.id}
							title={gallery.title}
							alias={gallery.alias}
							source={gallery.thumbnail.source}
						/>
					))}
			</div>
			<Next />
		</section>
	);
};

export default Realizations;
