import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Title from '../../components/Title';
import styles from './styles.module.css';
import { Type } from '../../types';

const NotFond: React.FC<RouteComponentProps> = () => (
	<section className={styles.container}>
		<Title type={Type.secondary} text="Stránka nebyla nenalezena" back />
	</section>
);

export default NotFond;
