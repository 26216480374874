import React, { useRef, useEffect, useState } from 'react';
import styles from './styles.module.css';

interface MasonryProps {
	children: JSX.Element[] | undefined;
	className?: string;
}

const Masonry = (props: MasonryProps): JSX.Element => {
	const { children, className } = props;
	const [imageCount, setImageCount] = useState<number>(0);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setImageCount(Math.round(React.Children.count(children) / 4));
	}, [children]);

	return (
		<div className={`${styles.container} ${className}`} ref={containerRef}>
			<div className={styles.column}>
				{React.Children.toArray(children)
					.slice(0, imageCount)
					.map((item) => item)}
			</div>
			<div className={styles.column}>
				{React.Children.toArray(children)
					.slice(imageCount, imageCount * 2)
					.map((item) => item)}
			</div>
			<div className={styles.column}>
				{React.Children.toArray(children)
					.slice(imageCount * 2, imageCount * 3)
					.map((item) => item)}
			</div>
			<div className={styles.column}>
				{React.Children.toArray(children)
					.slice(imageCount * 3, React.Children.count(children))
					.map((item) => item)}
			</div>
		</div>
	);
};

Masonry.defaultProps = {
	className: '',
};

export default Masonry;
