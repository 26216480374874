import React, { useState, useEffect, useRef, useContext } from 'react';
import { IGallery } from '../../types';
import { StoreContext } from '../../contexts';
import Image from '../Image';
import styles from './styles.module.css';

interface ICarousel {
	galleryId: number;
	timeout: number;
	children?: JSX.Element[] | JSX.Element | undefined;
}

const Carousel = (props: ICarousel): JSX.Element => {
	const { galleryId, timeout, children } = props;
	const [gallery, setGallery] = useState<IGallery | null>(null);
	const [index, setIndex] = useState<number>(0);
	const slidesRef = useRef<HTMLDivElement>(null);
	const { tardis } = useContext(StoreContext);

	useEffect(() => {
		tardis
			.getGalleryByID(galleryId)
			.then((result) => {
				setGallery(result);
			})
			.catch((error) => console.error(error));

		const interval = setInterval(() => {
			// eslint-disable-next-line no-shadow
			setIndex((index) => index + 1);
		}, timeout);

		return () => {
			clearInterval(interval);
		};
	}, [galleryId, tardis]);

	useEffect(() => {
		gallery && index < 0 && setIndex(gallery?.images.length - 1);
		gallery && index >= gallery?.images.length && setIndex(0);
	}, [index]);

	return (
		<section className={styles.container} id="home">
			{children}
			<div className={styles.slides} ref={slidesRef}>
				{gallery?.images.map((image, i) => (
					<Image
						key={image.id}
						source={image.source}
						alt={image.title}
						className={`${styles.slide} ${index === i ? styles.active : ''}`}
						responsive
					/>
				))}
			</div>
		</section>
	);
};

Carousel.defaultProps = {
	children: undefined,
};

export default Carousel;
