import React, { useState, useEffect, useContext } from 'react';
import { IImage, Type } from '../../types';
import Title from '../Title';
import Next from '../Next';
import Image from '../Image';
import { StoreContext } from '../../contexts';
import styles from './styles.module.css';

interface BeforeAfterProps {
	anchor: string;
}

const BeforeAfter = ({ anchor }: BeforeAfterProps): JSX.Element => {
	const [data, setImages] = useState<IImage[]>([]);
	const { tardis } = useContext(StoreContext);

	useEffect(() => {
		tardis.getGalleryByID(78).then(({ images }) => setImages(() => images.filter(({ state }) => state)));
	}, [tardis]);

	return (
		<section id={anchor}>
			<Title text="před/po" type={Type.secondary} />
			<div className={styles.container}>
				{data.map(({ id, title, source }) => (
					<Image alt={title} source={source} className={styles.image} key={id} />
				))}
			</div>
			<Next />
		</section>
	);
};

export default BeforeAfter;
