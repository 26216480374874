import React, { createContext } from 'react';
import Tardis from '../services';

interface StoreContextProps {
	tardis: Tardis;
}

interface StoreProviderProps {
	children: JSX.Element;
}

const tardis = new Tardis();

const StoreContext = createContext<StoreContextProps>({ tardis });

const StoreProvider = ({ children }: StoreProviderProps): JSX.Element => (
	<StoreContext.Provider value={{ tardis }}>{children}</StoreContext.Provider>
);

export default StoreContext;
export { StoreProvider };
