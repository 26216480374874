import React, { MouseEvent, useEffect, useState } from 'react';
import { useModal } from '../../hooks';
import styles from './styles.module.css';

interface ItemProps {
	title?: string;
	target: string;
	icon?: JSX.Element;
}

const Item = (props: ItemProps): JSX.Element => {
	const { title, target, icon } = props;
	const [targetElement, setTargetElement] = useState<HTMLElement | null>();
	const [isFocused, setFocused] = useState<boolean>(false);
	const { setModalVisible } = useModal();
	const offset = 80;

	useEffect(() => {
		setTargetElement(() => document.getElementById(target));

		const handleActive = () => {
			if (
				targetElement &&
				window.scrollY > targetElement?.offsetTop - offset &&
				window.scrollY < targetElement?.clientHeight + targetElement?.offsetTop - offset
			) {
				setFocused(true);
			} else {
				setFocused(false);
			}
		};

		window.addEventListener('scroll', handleActive);

		return () => window.removeEventListener('scroll', handleActive);
	}, [targetElement]);

	const handleClick = (event: MouseEvent) => {
		event.preventDefault();
		targetElement && targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		setModalVisible(false);
	};

	return (
		<a href={target} onClick={handleClick} className={`${styles.item} ${isFocused ? styles.focused : ''}`}>
			{title}
			{icon}
		</a>
	);
};

Item.defaultProps = {
	title: null,
	icon: null,
};

export default Item;
