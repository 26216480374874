import React, { createContext, useState } from 'react';

export interface ScrollContextProps {
	position: number;
	setPosition: React.Dispatch<React.SetStateAction<number>>;
}

interface ScrollProviderProps {
	children: JSX.Element;
}

const ScrollContext = createContext<ScrollContextProps>({ position: 0, setPosition: () => null });

const ScrollProvider = ({ children }: ScrollProviderProps): JSX.Element => {
	const [position, setPosition] = useState<number>(0);

	return <ScrollContext.Provider value={{ position, setPosition }}>{children}</ScrollContext.Provider>;
};

export default ScrollContext;
export { ScrollProvider };
