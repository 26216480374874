import { IGallery } from '../types';

export default class Tardis {
	private origin = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DOMAIN_STAGING : '';

	private cache: Cache | null = null;

	private cacheName = 'cache';

	constructor() {
		const getCache = async () => {
			let cache = null;
			if ('caches' in window) {
				await caches.delete(this.cacheName);
				cache = await caches.open(this.cacheName);
			}

			return cache;
		};

		getCache().then((cache) => {
			this.cache = cache;
		});
	}

	async getGalleryByID(id: number): Promise<IGallery> {
		const response = await this.fetch(`${this.origin}/api/1/gallery/${id}`);

		if (!response.ok) {
			throw Error(`Can not get gallery by id: ${id}!`);
		}

		return response.json();
	}

	async getGalleryByAlias(alias: string): Promise<IGallery> {
		const response = await this.fetch(`${this.origin}/api/1/gallery/${alias}`);

		if (!response.ok) {
			throw Error(`Can not get gallery by alias: ${alias}!`);
		}

		return response.json();
	}

	async find(key: string, value: string): Promise<IGallery[]> {
		const response = await this.fetch(`${this.origin}/api/1/gallery/find/${key}/${value}`);

		if (!response.ok) {
			throw Error(`Can not get galleries by ${key}: ${value}!`);
		}

		return response.json();
	}

	async fetch(request: string): Promise<Response> {
		const cache = await this.cache?.match(request);
		const response = cache ?? (await fetch(request));
		cache ?? this.cache?.add(request);

		return response;
	}
}
