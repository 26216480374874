import React from 'react';
import { Link } from '@reach/router';
import Logo from '../Logo';
import { back as backIcon } from '../../assets';
import styles from './styles.module.css';
import { Type } from '../../types';

interface TitleProps {
	text: string;
	subtext?: string;
	description?: string;
	type: Type;
	className?: string;
	back?: boolean;
}

const Title = ({ text, subtext, description, type, className, back = false }: TitleProps): JSX.Element => {
	const content = (): JSX.Element => (
		<div className={`${styles.wrapper} ${styles[type]} ${className}`}>
			<Logo className={styles.logo} />
			<p className={styles.text}>{text}</p>
			{!!subtext && <p className={styles.text}>{subtext}</p>}
			{!!description && <span className={styles.description}>{description}</span>}
		</div>
	);

	// eslint-disable-next-line eqeqeq
	return type == 'primary' ? (
		content()
	) : (
		<div className={styles.container}>
			{back && (
				<Link to="/" className={styles.back}>
					<img src={backIcon} alt="back" />
				</Link>
			)}
			{content()}
		</div>
	);
};

export default Title;
