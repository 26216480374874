import React from 'react';
import { forward } from '../../assets';
import styles from './styles.module.css';

interface NextProps {
	className?: string;
}

const Next = (props: NextProps): JSX.Element => {
	const { className } = props;
	return (
		<div className={`${styles.container} ${className}`}>
			<img src={forward} alt="next" className={styles.arrow} />
		</div>
	);
};

Next.defaultProps = {
	className: '',
};

export default Next;
