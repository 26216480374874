import React from 'react';
import { Router } from '@reach/router';
import { StoreProvider, ModalProvider, ScrollProvider } from './contexts';
import HomePage from './pages/HomePage';
import DetailPage from './pages/DetailPage';
import PressPage from './pages/PressPage';
import NotFound from './pages/NotFound';

const App = (): JSX.Element => (
	<div className="App">
		<StoreProvider>
			<ModalProvider>
				<ScrollProvider>
					<Router>
						<HomePage path="/" />
						<DetailPage path="realizace/:alias" />
						<PressPage path="press/:alias" />
						<NotFound default />
					</Router>
				</ScrollProvider>
			</ModalProvider>
		</StoreProvider>
	</div>
);

export default App;
