import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Carousel from '../../components/Carousel';
import Title from '../../components/Title';
import Menu from '../../components/Menu';
import Realizations from '../../components/Realizations';
import Consultations from '../../components/Consultations';
import Contact from '../../components/Contact';
import Press from '../../components/Press';
import Footer from '../../components/Footer';
import Next from '../../components/Next';
import BeforeAfter from '../../components/BeforaAfter';
import { useScroll, useTitle } from '../../hooks';
import { Type } from '../../types';
import styles from './styles.module.css';

const HomePage: React.FC<RouteComponentProps> = (): JSX.Element => {
	const { position, setPosition } = useScroll();
	const setTitle = useTitle();

	useEffect(() => {
		const { scroll } = window;
		const handleScroll = () => setPosition(Math.floor(window.scrollY));

		setTitle('');
		setTimeout(() => {
			scroll({
				top: position,
				left: 0,
				behavior: 'smooth',
			});

			window.addEventListener('scroll', handleScroll);
		}, 500);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<Menu />
			<main>
				<Carousel galleryId={1} timeout={4000}>
					<Title
						text="terapie prostoru"
						subtext="konzultace při vytváření interiérů"
						type={Type.primary}
						className={styles.title}
					/>
					<Next className={styles.next} />
				</Carousel>
				<BeforeAfter anchor="predpo" />
				<Realizations anchor="realizace" />
				<Consultations anchor="konzultace" />
				<Contact anchor="kontakt" />
				<Press />
				<Footer />
			</main>
		</>
	);
};

export default HomePage;
