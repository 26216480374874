import React, { useEffect, useState, useContext } from 'react';
import { StoreContext } from '../../contexts';
import { IGallery, Type } from '../../types';
import Grid from './Grid';
import Title from '../Title';
import styles from './styles.module.css';

const Press = (): JSX.Element => {
	const [press, setPress] = useState<IGallery[]>([]);
	const [media, setMedia] = useState<IGallery[]>([]);
	const { tardis } = useContext(StoreContext);

	useEffect(() => {
		tardis.find('tag', '3').then((result) => setMedia(() => result.filter(({ state }) => state)));
		tardis.find('tag', '4').then((result) => setPress(() => result.filter(({ state }) => state)));
	}, []);

	return (
		<section className={styles.container} id="press">
			<Title text="press" type={Type.secondary} />
			<p className={styles.subtitle}>
				interiéry <b>apartment doctor</b> v médiích
			</p>
			<Grid data={media} columnWidth={22} rowHeigh={40} className={styles.media} />
			<p className={`${styles.subtitle} ${styles.anchor}`} id="press-pick">
				<b>martina schneider králová</b> - výběr z článků pro časopisy
			</p>
			<Grid data={press} className={styles.press} columnWidth={11} rowHeigh={13} />
		</section>
	);
};

export default Press;
