const useTitle = (): ((title: string) => void) => {
	const setTitle = (title: string) => {
		const siteName = process.env.REACT_APP_WEBSITE_NAME?.replace('-', ' ') || '';
		document.title = title ? `${siteName}–${title}` : siteName;
	};

	return setTitle;
};

export default useTitle;
