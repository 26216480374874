import React from 'react';
import styles from './styles.module.css';

interface ButtonProps {
	href: string;
	icon: string;
	className?: string;
}

const Link = (props: ButtonProps): JSX.Element => {
	const { href, icon, className } = props;
	return (
		<a href={href} className={`${styles.container} ${className}`}>
			<img src={icon} alt="icon" className={styles.icon} />
		</a>
	);
};

Link.defaultProps = {
	className: '',
};

export default Link;
