import React, { useRef, useEffect, useState } from 'react';
import { Link } from '@reach/router';
import Image from '../Image';
import { ISource } from '../../types';
import styles from './styles.module.css';

interface ItemProps {
	title: string;
	alias: string;
	source: ISource;
}

const Item = (props: ItemProps): JSX.Element => {
	const { title, alias, source } = props;
	const [height, setHeight] = useState<number>(0);
	const linkRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		const handleResize = () => {
			if (linkRef.current?.clientWidth) {
				setHeight(linkRef.current?.clientWidth);
			}
		};
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [linkRef]);

	return (
		<Link to={`realizace/${alias}`} className={styles.item} ref={linkRef} style={{ height: `${height}px` }}>
			<span className={styles.title}>{title}</span>
			{source && <Image alt={title} source={source} className={styles.image} responsive />}
		</Link>
	);
};

export default Item;
