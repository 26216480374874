import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { StoreContext } from '../../contexts';
import Image from '../../components/Image';
import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import { useTitle } from '../../hooks';
import { IGallery } from '../../types';
import styles from './styles.module.css';

interface PressPageProps extends RouteComponentProps {
	alias?: string;
}

const PressPage = (props: PressPageProps): JSX.Element => {
	const { alias } = props;
	const [gallery, setGallery] = useState<IGallery>();
	const { tardis } = useContext(StoreContext);
	const setTitle = useTitle();

	const handleClick = () => navigate('/');

	useEffect(() => {
		alias &&
			tardis
				.getGalleryByAlias(alias)
				.then((response) => setGallery(response))
				.catch(() => {
					navigate('/not-found');
				});
	}, []);

	useEffect(() => {
		setTitle(gallery?.title || '');
	}, [gallery]);

	return (
		<div className={styles.container}>
			<Logo onClick={handleClick} className={styles.logo} />
			{gallery?.images.map(
				(image, index) =>
					index > 0 && (
						<Image
							key={image.id.toString()}
							alt={image.title}
							source={image.source}
							className={styles.image}
						/>
					)
			)}
			<Footer />
		</div>
	);
};

PressPage.defaultProps = {
	alias: '',
};

export default PressPage;
