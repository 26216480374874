import React from 'react';
import Title from '../Title';
import Columns from '../Columns';
import Link from '../Link';
import Next from '../Next';
import styles from './styles.module.css';
import { avatar, facebook, instagram } from '../../assets';
import { SectionProps, Type } from '../../types';
import constants from '../../constants';

const Contact = (props: SectionProps): JSX.Element => {
	const { anchor } = props;
	return (
		<section id={anchor} className={styles.container}>
			<Columns>
				<img alt="Martina Schneider Králová" src={avatar} className={`${styles.image} ${styles.uno}`} loading="lazy" />
				<div className={styles.wrapper}>
					<Title text="kontakt" type={Type.secondary} className={styles.title} />
					<p>
						<span className={styles.subtitle}>martina schneider králová</span>
						<br />
						<span className={styles.text}>
							čerpala zkušenosti při studiu kulturní a sociální antropologie na Filozofické fakultě
							Univerzity Karlovy, jako redaktorka rubriky Decor v ELLE, později šéfredaktorka ELLE, jako
							členka Akademie designu ČR, při práci na textech a supervizi projektů pro časopisy Dolce
							Vita, Proč ne?! HN, Respekt, ELLE Decoration..., a při realizacích interiérů ve spolupráci s
							Hynkem Schneiderem. Jejich interiéry jsou neformální, eklektické a cítíte se v nich
							svobodně. Díky množství míst, kterými Martina prošla při své práci, rozhovorům s tvůrci a
							vlastním zkušenostem dokáže dnes analyzovat prostor a jako apartment doctor pomáhat lidem,
							aby se jim v něm líbilo.
						</span>
					</p>
					<p>
						<span className={styles.subtitle}>hynek schneider</span>
						<br />
						<span className={styles.text}>
							je hudební skladatel, který se při realizacích interiérů vrátil k odkazu svého děda
							architekta Hynka Schneidera, žáka Jana Kotěry a spolupracovníka Josefa Gočára.
						</span>
					</p>
					<p className={styles.text}>
						<span>Realizace interiérů</span>
						<a href="#realizace" className={styles.link}>
							ZDE
						</a>
						.<br />
						<span>Výběr z článků pro časopisy</span>
						<a href="#press-pick" className={styles.link}>
							ZDE
						</a>
						.<br />
						<span>Aktuální návody, nápady a postřehy na</span>
						<Link href={constants.facebookUrl} icon={facebook} className={styles.socialLink} />
						<Link href={constants.instagramUrl} icon={instagram} className={styles.socialLink} />
					</p>
					<p className={styles.text}>
						<a href="mailto:martina.kralova@apartmentdoctor.cz">martina.kralova@apartmentdoctor.cz</a>
						<br />
						<span>tel. </span>
						<a href="tel:420603549820">+420 603 549 820</a>
					</p>
				</div>
			</Columns>
			<Next />
		</section>
	);
};

export default Contact;
