import React from 'react';
import { Link } from '@reach/router';
import Image from '../Image';
import { IGallery } from '../../types';
import styles from './styles.module.css';

interface GridProps {
	data: IGallery[];
	className?: string;
	columnWidth: number;
	rowHeigh: number;
}

const Grid = (props: GridProps): JSX.Element => {
	const { data, className, columnWidth, rowHeigh } = props;

	return (
		<div
			className={`${styles.grid} ${className}`}
			style={{
				gridTemplateColumns: `repeat(auto-fit, minmax(${columnWidth}rem, 1fr))`,
				gridAutoRows: `minmax(${rowHeigh - 5}, ${rowHeigh}rem)`,
			}}>
			{data?.map(({ thumbnail, alias, title, description }) => (
				<Link to={`/press/${alias}`} key={alias} className={styles.link}>
					<span className={styles.title}>{description || title}</span>
					{thumbnail.source && (
						<Image source={thumbnail.source} alt={title} className={styles.image} responsive />
					)}
				</Link>
			))}
		</div>
	);
};

Grid.defaultProps = {
	className: '',
};

export default Grid;
