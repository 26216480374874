import React from 'react';
import { award } from '../../assets';
import Title from '../Title';
import Columns from '../Columns';
import Next from '../Next';
import { SectionProps, Type } from '../../types';
import styles from './styles.module.css';

const Consultations = (props: SectionProps): JSX.Element => {
	const { anchor } = props;

	return (
		<section id={anchor} className={styles.container}>
			<Columns>
				<div className={styles.asside}>
					<Title
						text="konzultace"
						description="martina schneider králová"
						type={Type.secondary}
						className={styles.title}
					/>
					<img src={award} alt="award" className={styles.award} />
				</div>
				<div className={styles.wrapper}>
					<p>
						<b>apartment doctor</b> analyzuje prostor, zjistí, co je zdravé a použitelné, s čím se
						rozloučit, a co je třeba, aby se stav interiéru zlepšil.
					</p>

					<p>
						<span style={{ fontSize: '1rem' }}>konzultace</span> můžete využít, když...
						<br /> ...potřebujete změnit styl
						<br /> ...chystáte se na rekonstrukci
						<br /> ...jste na začátku a potřebujete si ujasnit, co vlastně chcete
						<br /> ...jste uprostřed a nevíte jak dál
						<br /> ...máte pocit, že to není ono, a potřebujete zjistit, jak to zlepšit
						<br /> ...nejste si jisti výběrem zařízení a potřebujete nasměrovat
						<br /> ...snažíte se vytvořit uvolněnou, neformální atmosféru
					</p>
					<p>
						Tak jako stylistka probírá šatník a pomůže vám najít vlastní styl, doporučí oblečení, nebo se
						stane průvodcem při nákupech, <b>apartment doctor</b> dělá totéž, ale v interiéru. Naučí vás,
						jak přemýšlet o prostoru. Pomůže najít základní ideu podle toho, kdo jste a jak žijete, což vám
						pak usnadní výběr materiálů a zařízení. Když zjistíte, co je pro vás dobré, můžete pokračovat
						sami, nebo dostat konkrétní tipy a návody.
					</p>
					<p>
						Před konzultací pošlete pár fotek nebo video celého prostoru a jednoduše napište, co chcete
						řešit.
					</p>
					<p>
						<small>
							Chcete-li získat přehled o zákonitostech vytváření uvolněné, neformální atmosféry v
							prostoru, můžete se zúčastnit semináře <b>apartment tour</b> s ukázkami řešení přímo v
							jednom z realizovaných interiérů, termíny a podrobnosti hledejte na facebooku nebo si o ně
							napište.
						</small>
					</p>
				</div>
			</Columns>
			<Next />
		</section>
	);
};
export default Consultations;
