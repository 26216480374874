import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, navigate, Link } from '@reach/router';
import { StoreContext } from '../../contexts';
import { IGallery, Type } from '../../types';
import Image from '../../components/Image';
import Title from '../../components/Title';
import Footer from '../../components/Footer';
import Masonry from '../../components/Masonry';
import { useTitle } from '../../hooks';
import styles from './styles.module.css';
import { back as backIcon } from '../../assets';

interface DetailPageProps extends RouteComponentProps {
	alias?: string;
}

const DetailPage = (props: DetailPageProps): JSX.Element => {
	const { alias } = props;
	const [gallery, setGallery] = useState<IGallery>();
	const { tardis } = useContext(StoreContext);
	const setTitle = useTitle();

	useEffect(() => {
		alias &&
			tardis
				.getGalleryByAlias(alias)
				.then((response) => setGallery(response))
				.catch(() => {
					navigate('/not-found');
				});
	}, []);

	useEffect(() => {
		setTitle(gallery?.title || '');
	}, [gallery]);

	return (
		<div className={styles.container}>
			{gallery && <Title text={gallery.title} description={gallery.description} type={Type.tertiary} back />}
			<Masonry className={styles.masonry}>
				{gallery?.images.map((image) => (
					<Image
						key={image.id.toString()}
						alt={image.title}
						source={image.source}
						className={styles.image}
						responsive
						popup
					/>
				))}
			</Masonry>
			<Link to="/" className={styles.back}>
				<img src={backIcon} alt="back" />
			</Link>
			<Footer />
		</div>
	);
};

DetailPage.defaultProps = {
	alias: '',
};

export default DetailPage;
