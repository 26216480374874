import React, { createContext, useState, useEffect } from 'react';
import Modal from '../components/Modal';

export interface ModalContextProps {
	setModalVisible: (state: boolean) => void;
	setModalContent: (modalContent: JSX.Element) => void;
}

interface ModalProviderProps {
	children: JSX.Element;
}

const ModalContext = createContext<ModalContextProps>({ setModalVisible: () => null, setModalContent: () => null });

const ModalProvider = ({ children }: ModalProviderProps): JSX.Element => {
	const [isVisible, setVisible] = useState<boolean>(false);
	const [content, setContent] = useState<JSX.Element | null>(null);
	const setModalVisible = (state: boolean) => setVisible(state);
	const setModalContent = (modalContent: JSX.Element) => setContent(modalContent);
	const handleClose = () => setVisible(false);

	useEffect(() => {
		const handleKeyDown = ({ code }: KeyboardEvent) => code === 'Escape' && setModalVisible(false);
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<ModalContext.Provider value={{ setModalVisible, setModalContent }}>
			{isVisible && <Modal onClose={handleClose}>{content}</Modal>}
			{children}
		</ModalContext.Provider>
	);
};

export default ModalContext;
export { ModalProvider };
