import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.css';
import { close } from './assets';

interface ModalProps {
	onClose: () => void;
	children: JSX.Element | JSX.Element[] | null;
}

const Modal = (props: ModalProps): ReactPortal => {
	const { onClose, children } = props;
	const element = document.createElement('div');
	element.classList.add(styles.container);
	const modalRoot = document.getElementById('modal-root');

	React.useEffect(() => {
		modalRoot?.appendChild(element);

		return () => {
			modalRoot?.removeChild(element);
		};
	});

	const content = () => (
		<div className={styles.wrapper}>
			<button className={styles.button} type="button" onClick={onClose}>
				<img src={close} alt="close" />
			</button>
			<>{children}</>
		</div>
	);

	return ReactDOM.createPortal(content(), element);
};

export default Modal;
