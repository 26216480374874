import React, { useContext, useEffect, useState } from 'react';
import Item from './Item';
import { ModalContext } from '../../contexts';
import styles from './styles.module.css';
import { facebook, home, menu, instagram } from '../../assets';
import constants from '../../constants';
import Link from '../Link';

const Menu: React.FC = (): JSX.Element => {
	const { setModalVisible, setModalContent } = useContext(ModalContext);
	const [active, setActive] = useState<boolean>(false);

	useEffect(() => {
		const handleActive = () => setActive(window.scrollY > 250);
		window.addEventListener('scroll', handleActive);

		return () => window.removeEventListener('scroll', handleActive);
	}, []);

	const items = () => (
		<>
			<Item title="před/po" target="predpo" />
			<Item title="realizace" target="realizace" />
			<Item title="konzultace" target="konzultace" />
			<Item title="kontakt" target="kontakt" />
			<Item title="press" target="press" />
		</>
	);

	const handleMenu = () => {
		setModalVisible(true);
		setModalContent(<div className={styles.modal}>{items()}</div>);
	};

	return (
		<nav className={`${styles.container} ${active ? styles.active : ''}`}>
			<div className={styles.navigationWrapper}>
				<Item icon={<img src={home} alt="home" className={styles.home} />} target="home" />
				{items()}
			</div>
			<div className={styles.socialWrapper}>
				<Link href={constants.facebookUrl} icon={facebook} />
				<Link href={constants.instagramUrl} icon={instagram} />
				<button type="button" onClick={handleMenu} className={styles.hamburger}>
					<img src={menu} alt="menu" className={styles.icon} />
				</button>
			</div>
		</nav>
	);
};

export default Menu;
