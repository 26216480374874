/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { logo } from '../../assets';

interface LogoProps {
	className?: string;
	onClick?: () => void;
}

const Logo = ({ className, onClick }: LogoProps): JSX.Element => (
	// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
	<img src={logo} alt="Apartmentdoctor" onClick={onClick && onClick} className={className} data-testid="logo" />
);

export default Logo;
