// eslint-disable-next-line no-shadow
export enum Type {
	primary = 'primary',
	secondary = 'secondary',
	tertiary = 'tertiary',
}

export interface IThumbnail {
	'2560': string;
	'1920': string;
	'1366': string;
	'1024': string;
	'640': string;
	'320': string;
	'160': string;
}

export interface ISource {
	'image/jpeg': IThumbnail;
}

export interface IImage {
	id: number;
	title: string;
	source: ISource;
	state: boolean;
}

export interface IGallery {
	id: number;
	title: string;
	description: string;
	alias: string;
	state: boolean;
	images: IImage[];
	source: ISource;
	thumbnail: IImage;
}

export interface SectionProps {
	anchor: string;
}
