import React from 'react';
import styles from './styles.module.css';

interface ColumnsProps {
	children: JSX.Element[];
}

const Columns = (props: ColumnsProps): JSX.Element => {
	const { children } = props;

	return <div className={styles.container}>{children}</div>;
};

export default Columns;
